<template>
  <v-menu
    open-on-hover
    :offset-y="offset"
  >
    <template v-slot:activator="{ on }">
      <div class="my-2">
        <v-btn
          size="52"
          color="#09A1E0"
          fab
          small
          dark
          v-on="on"
        >
          <span class="subtitle-1">{{ initials }}</span>
        </v-btn>
      </div>
    </template>
    <v-card v-if="userInfo" class="account-popup">
      <v-list>
        <v-list-item>
          <v-list-item-avatar v-if="userInfo.profile">
            <img :src="userInfo.profile" alt="profile-pic" />
          </v-list-item-avatar>
          <v-list-item-avatar
            v-else
            color="#09A1E0"
            size="52"
            fab
            small
            dark
          >
            <span class="white--text subtitle-1">{{ initials }}</span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ fullName }}</v-list-item-title>
            <v-list-item-subtitle>{{ userInfo.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list>
        <v-list-item>
          <v-btn
            text
            light
            small
            @click="redirectToSelfService">Account Settings</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            text
            light
            small
            @click="logout">Logout</v-btn>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>


<script>
export default {
  name: "profile-button",
  props: {
    userInfo: Object,
  },
  data() {
    return {
      offset: true,
      initials: '',
      fullName: '',
      email: ''
    };
  },
  watch: {
    userInfo: "getUserName"
  },
  created() {
    this.getUserName();
  },
  methods: {
    getUserName() {
      if (this.userInfo) {
        const initialF = this.userInfo.given_name.substring(0, 1).toUpperCase();
        const initialL = this.userInfo.family_name.substring(0, 1).toUpperCase();
        this.initials = initialF + initialL;

        const firstName = initialF + this.userInfo.given_name.substring(1).toLowerCase();
        const lastName = initialL + this.userInfo.family_name.substring(1).toLowerCase();
        this.fullName = firstName + ' ' + lastName;
      }
    },
    async logout() {
      await this.$authService.signOut();
    },
    redirectToSelfService() {
      const url = `${process.env.VUE_APP_SELF_SERVICE_URL}/profile`;
      window.open(url, '_blank');
    },
    showProfile() {
      this.$router.push({
        name: "settings",
        params: {
          focusTab: 0,
        },
      });
    },
  }
};
</script>


<style lang="scss" scoped>
.account-popup {
  min-width: min-content !important;
}
</style>
