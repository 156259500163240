import _Vue from "vue";
import { MapSSO } from "@moodys-ma-platform/auth-sdk";

const _ssoService = new MapSSO({
    clientId: process.env.VUE_APP_SSO_CLIENT_ID,
    redirectUri: window.location.protocol + '//' + window.location.host + '/callback',
    issuer: `${process.env.VUE_APP_SSO_DOMAIN}`
});

const watchAuthState = async (authState) => {
    if(!authState.isAuthenticated) {
        await guardHandler(window.location.origin);
    }
}

function waitForSsoService() {
    return new Promise((resolve) => {
        const intervalId = setInterval(() => {
            if (window.__ssoServiceStarted) {
                clearInterval(intervalId);
                resolve(window.__ssoServiceStarted);
            }
        }, 100);
    });
}

const guardHandler = async (originalUri) => {
    _ssoService.setOriginalUri(originalUri)

    await _ssoService.signInWithRedirect()
}

export const NavigationGuardMixin = _Vue.extend({
    async beforeRouteEnter (to, from, next) {
        if(!window.__ssoServiceStarted) {
            await waitForSsoService()
        }
        if (to.matched.some(record => record.meta.requiresAuth)) {
            // track the originalUri for guardHandler
            const originalUri = window.location.origin + to.fullPath

            const isSessionActive = await _ssoService.checkSession()
            if(!isSessionActive) {
                _ssoService.tokenManager.clear()
            }

            // guard the secure route based on if the user is authenticated
            const isAuthenticated = await _ssoService.isAuthenticated()

            if (!isAuthenticated) {
                await guardHandler(originalUri)
            } else {
                // If authenticated, listen for auth changes
                _ssoService.authStateManager.subscribe('authStateChange', watchAuthState)
                next()
            }
        } else {
            next()
        }
    },
    async beforeRouteLeave (to, from, next) {
        // unsubscribe from authStateChange if leaving a route that requires authentication
        if (from.matched.some(record => record.meta.requiresAuth)) {
            _ssoService.authStateManager.unsubscribe('authStateChange', watchAuthState)
        }
        next()
    }
})

async function install(Vue) {
    window.__ssoServiceStarted = false
    Vue.mixin(NavigationGuardMixin)

    Vue.prototype.$authService = _ssoService

    await _ssoService.start()

    window.__ssoServiceStarted = true
}

export default { install }