<script lang="ts">
export default {
  name: "ImplicitCallback",
  async beforeMount() {
    try {
      await this.$authService.handleRedirect();
    } catch(err) {
      console.error("Error during implicit callback handling:", err);
      this.$router.push({
        name: "home",
      });
    }
  },
  render() {
    // typescript not allow empty render function, here's the workaround for renderless component
    return this.$scopedSlots.default && this.$scopedSlots.default({});
  }
};
</script>
