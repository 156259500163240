// NOTE: Commented out code in this page is from the custom Okta widget

import Vue from 'vue';
import Router from 'vue-router';

import ImplicitCallback from '@/views/ImplicitCallback';
import Help from '@/components/Help';
import Home from '@/components/Home';
import Categories from '@/views/CategoryPage';
import Search from '@/views/SearchPage';
import Welcome from '@/components/Welcome';
import NotFound from '@/components/PageNotFound';

import "@/assets/fonts/fonts.css";
import "./index.css";
import SsoService from "@/utils/sso-service.js";

Vue.use(Router);
Vue.use(SsoService);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/callback',
      component: ImplicitCallback,
    },
    {
      path: '/settings',
      name: 'settings',
      beforeEnter() {
        window.location.href = process.env.VUE_APP_SELF_SERVICE_URL + '/profile';
      }
    },
    {
      path: '/categories/:category',
      name: 'categories',
      component: Categories,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/help',
      name: 'help',
      component: Help,
      props: true,
    },
    {
      path: '/search/:keyword',
      name: 'search',
      component: Search,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
      props: true,
    },
    {
      path: '/404',
      name: 'not-found',
      component: NotFound,
    },
    {
      path: '*',
      redirect: '/404',
    },
  ]
});

export default router;
